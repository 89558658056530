import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=97f731ee&scoped=true&"
import script from "./Projects.vue?vue&type=script&lang=js&"
export * from "./Projects.vue?vue&type=script&lang=js&"
import style0 from "./Projects.vue?vue&type=style&index=0&id=97f731ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97f731ee",
  null
  
)

export default component.exports